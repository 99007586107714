import React, { useEffect } from "react";

import Image from "next/image";
import Link from "next/link";

import splitImg from "../../public/images/split/split-8.webp";

import sal from "sal.js";

const Split = () => {
  useEffect(() => {
    sal();
  }, []);

  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Instant Content Creation with AI
                    </h4>

                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Unlock Conversion-Driven Content: Business Bios, Facebook
                      Ads, Product Descriptions, Emails, Landing Pages, Social
                      Ads, and Beyond.
                    </p>

                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <li>- Craft Articles in Under 20 Seconds.</li>
                      <li>- Generate High-Quality Images in Seconds.</li>
                      <li>- Create Videos in Minutes.</li>
                      <li>- Save $1000s on Multiple Subscriptions.</li>
                      <li>- Reclaim Hundreds of Valuable Hours with AI</li>
                    </ul>

                    <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <Link className="btn-default" href="/auth/signin">
                        Get Started Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="create-something">
            <div className="create-something__shape-2 float-bob-y"></div>
            <div className="create-something__shape-3 float-bob-x"></div>

            <div className="container">
              <div className="create-something__inner">
                <div className="create-something__shape-1" style={{ backgroundImage: 'url(/images/shapes/create-something-shape-1.png)' }}></div>
                
                <div className="create-something__star-1 zoominout">
                  <img src="/images/shapes/create-something-start-1.png" alt="create something with AI" />
                </div>

                <div className="row">
                  <div className="col-xl-6">
                    <div className="create-something__left">
                      <div className="create-something__content">
                        <h3 className="create-something__title text-light m-0">
                          Let’s Create Something  Awesome with <span>Cre8teGPT</span>
                        </h3>

                        <p className="create-something__text text-light m-0">Your All-in-One AI Content Creation Platform that helps you create content faster and better.</p>
                        
                        <div className="create-something__btn-two-box">
                          <Link href="/auth/signin" className="thm-btn-three create-something__btn-two">Get Started
                            Free</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="create-something__right">
                      <ul className="create-something__gallery">
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-1.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-1.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-2.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-2.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-3.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-3.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-4.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-4.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-5.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-5.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-6.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-6.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-7.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-7.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-8.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-8.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-9.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-9.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-10.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-10.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-11.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-11.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="create-something__gallery-single">
                            <img src="/images/gallery/create-something-gallery-1-12.jpg" alt="create something with AI" />
                            <div className="create-something__gallery-icon">
                              <a className="img-popup" href="/images/gallery/create-something-gallery-1-12.jpg"><span className="icon-add"></span></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;
